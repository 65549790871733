import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Lieux } from '../lieux';

@Component({
  selector: 'app-generateur-lieux',
  templateUrl: './generateur-lieux.component.html',
  styleUrls: ['./generateur-lieux.component.css']
})
export class GenerateurLieuxComponent implements OnInit {
  @ViewChild('f') signupForm: NgForm;
  urlLieuxService = '';
  
  reponseGetLieux: Lieux;

  nombre: number = 1;
  listeLieux: Array<String> = new Array<String>();
  afficherListeLieux: boolean = false;

  onSubmit(form: NgForm) {
    
    this.apiService.GetLieux(this.nombre)
      .subscribe((lieuxResponse: Lieux) => {
        this.reponseGetLieux = lieuxResponse;
        this.afficherListeLieux = lieuxResponse.listeLieux != null;
      });
  }

  ngOnInit() { }
  constructor(private apiService: ApiService) {}
}
