import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Elite } from '../elite';

@Component({
  selector: 'app-generateur-elite',
  templateUrl: './generateur-elite.component.html',
  styleUrls: ['./generateur-elite.component.css']
})
export class GenerateurEliteComponent implements OnInit {
  @ViewChild('f') signupForm: NgForm;
  typeElite = '';
  tier = 1;
  amelioration = '';
  porteArmure = '';
  urlEliteService = '';
  
  reponseGetElite: Elite;

  afficherAmelioration: boolean = false;
  afficherArme: boolean = false;
  afficherArmure: boolean = false;
  afficherMod: boolean = false;
  aTalent: boolean = false;

  onSubmit(form: NgForm) {
    this.typeElite = this.signupForm.value.typeElite;
    this.tier = this.signupForm.value.tier;
    this.amelioration = this.signupForm.value.amelioration;
    this.porteArmure = this.signupForm.value.porteArmure;
    
    this.apiService.GetElite(this.typeElite, this.tier, this.amelioration, this.porteArmure)
      .subscribe((eliteResponse: Elite) => {
        this.reponseGetElite = eliteResponse;

        this.afficherAmelioration = eliteResponse.amelioration.nom != "BASIQUE";
        this.afficherArme = eliteResponse.arme != null;
        this.afficherArmure = eliteResponse.armure != null;
        this.afficherMod = eliteResponse.mod != null;
        this.aTalent = eliteResponse.listeTalent != null;
      });
  }

  ngOnInit() { }
  constructor(private apiService: ApiService) {}
}