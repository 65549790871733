import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { Elite } from './elite';
import { Lieux } from './lieux';
import { Quete } from './quete';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiEliteURL: string = 'http://163.172.36.96:8080/jdrgen/elite/';
  apiLieuxURL: string = 'http://163.172.36.96:8080/jdrgen/places/';
  apiQueteURL: string = 'http://163.172.36.96:8080/jdrgen/quest';

  constructor(private httpClient: HttpClient) {}

  GetElite(type: string, tier: number, amelioration: string, porteUneArmure: string): Observable<Elite> {
    return this.httpClient.get<Elite>(this.apiEliteURL + type + '/' + tier + '/' + amelioration + '/' + porteUneArmure);
  }

  GetLieux(nombre: number): Observable<Lieux> {
    return this.httpClient.get<Lieux>(this.apiLieuxURL + nombre);
  }

  GetQuete(): Observable<Quete> {
    return this.httpClient.get<Quete>(this.apiQueteURL);
  }
}