import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { Quete } from '../quete';

@Component({
  selector: 'app-generateur-quete',
  templateUrl: './generateur-quete.component.html',
  styleUrls: ['./generateur-quete.component.css']
})
export class GenerateurQueteComponent implements OnInit {
  @ViewChild('f') signupForm: NgForm;
  urlQueteService = '';
  
  reponseGetQuete: Quete;

  listeQuete: Array<String> = new Array<String>();
  afficherQuete: boolean = false;

  onSubmit(form: NgForm) {
    
    this.apiService.GetQuete()
      .subscribe((queteResponse: Quete) => {
        this.reponseGetQuete = queteResponse;
        this.afficherQuete = queteResponse.depart != null;
      });
  }

  ngOnInit() {
  }

  constructor(private apiService: ApiService) {}
}
